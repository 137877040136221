import React from "react";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    carousel: {
        width: "60vw",
        margin: "3rem auto",
        // height: '100%',
        // overflow: "hidden",
        // "& *": {
        //     height: "100%"
        // }
    },
    imageContainer: {
        // height: "100%",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // overflow: "hidden",
    },
    image: {
        // flexShrink: 0,
        // minWidth: "100%",
        // minHeight: "100%",
    },
    revText:{
        fontStyle: "italic"
    },
    revName:{
        fontWeight: "bold",
    },
}));

export default function Testimonials() {
    const classes = useStyles();
    return (
        <Carousel
            autoPlay={true}
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop={true}
            dynamicHeight={false}
            className={classes.carousel}
            interval={2000}
        >
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "No matter what, Amrish Prasher is the best there is all
                    around! He understood our needs perfectly. Thank you so much
                    for your help."
                </Typography>
                <Typography className={classes.revName}>Apurav Gupta</Typography>
            </div>
               <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "Amrish Prasher sold my property quick and easy. Would
                    recommend him 100%. He answered all my questions and doubts
                    I had when selling my home. Will most definitely work with
                    him again."
                </Typography>
                <Typography className={classes.revName}>Jagadees J.</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "One of the biggest decision for most of us is buying a
                    house. One needs to consider all the financial and economic
                    aspects along with choosing something that fits your dreams.
                    I know Amrish Prasher for the past 8 years. Amrish worked
                    around the clock to find our dream house. His knowledge and
                    professionalism are impressive. He supported us with every
                    decision and took care of our needs and lot of times also
                    stopped me before making the wrong ones. It is never just
                    about closing the sale with Armish. He focuses on building
                    long term relationships."
                </Typography>
                <Typography className={classes.revName}>Naveen</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "I found Amrish very courteous, professional and
                    knowledgeable regarding the Real Estate market and needs of
                    the buyer. He was great in setting a sound price for my
                    property which enabled a deal to be agreed upon within a
                    week. I have sold my property through Mr. Amrish Prasher.
                    His advertising was on point and he was prompt in
                    following-up to my queries. This aided the stress-free sale
                    of my property. I recommend Amrish as a realtor."
                </Typography>
                <Typography className={classes.revName}>Surinder Malhotra</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "Thank you Amrish for the great service. I loved your prompt
                    attitude and the determination to find the right thing. I am
                    very pleased with our transaction."
                </Typography>
                <Typography className={classes.revName}>Pankaj Sharma</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "Amrish is promising and dedicated.Since long I was trying
                    to get a good price for my house. Finally, I reached out to
                    Amrish and it was all hassle free after that. I really thank
                    him for the help."
                </Typography>
                <Typography className={classes.revName}>Vipan Sharma</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "Really good. If you aren't sure, always go for Amrish
                    Prasher. I will recommend you to my colleagues."
                </Typography>
                <Typography className={classes.revName}>Rohtas Garg</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "The service was excellent. Needless to say we are extremely
                    satisfied with the results."
                </Typography>
                <Typography className={classes.revName}>Vivek Jain</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "Amrish is skilled resourceful and hard working. He gave me
                    his full attention and even made sure to check in with me
                    frequently. He made the all the paperwork breezy. I would
                    definitely go with him again."
                </Typography>
                <Typography className={classes.revName}>Madhuri Gunapalli</Typography>
            </div>
            <div className={classes.imageContainer}>
                <Typography className={classes.revText}>
                    "Over the past couple of years, I have both bought and sold
                    a house. I feel fortunate to say that both of these
                    transactions were under the supervision of Amrish Prasher.
                    He has what it takes to get you the best deal."
                </Typography>
                <Typography className={classes.revName}>Makhan Singh</Typography>
            </div>
        </Carousel>
    );
}
