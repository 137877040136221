import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import SlideShow from "../components/slideshow";
import Hidden from "@material-ui/core/Hidden";
import CallToAction from "./callToAction";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    slides: {
        position: "absolute",
        width: "100%",
        height: "45vw",
        [theme.breakpoints.down("sm")]: {
            height: "65vw",
        },
        [theme.breakpoints.down("md")]: {
            height: "75vw",
        },
    },
    highlightGrid: {
        position: "absolute",
        width: "100%",
        height: "45vw",
        [theme.breakpoints.down("sm")]: {
            height: "65vw",
        },
        [theme.breakpoints.down("md")]: {
            height: "75vw",
        },
    },
    highlightContent: {
        width: "35vw",
        [theme.breakpoints.down("md")]: {
            width: "45vw",
        },
        textAlign: "center",
        backgroundColor: "#cccccccc",
        backdropFilter: "blur(4px)",
        // color: "white",
        borderRadius: "10px",
    },
    logo: {
        maxWidth: "100%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "65%",
        },
        margin: "auto",
    },
    highlightText: {
        fontSize: "2rem",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.9rem",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.7rem",
        },
        color: "#fff",
    },
    photoGrid: {
        position: "relative",
        width: "100%",
        height: "45vw",
        [theme.breakpoints.down("sm")]: {
            height: "65vw",
        },
        [theme.breakpoints.down("md")]: {
            height: "75vw",
        },
    },
    image: {
        width: "20%",
        [theme.breakpoints.down("md")]: {
            width: "30%",
        },
        position: "absolute",
        bottom: "0",
        filter: "drop-shadow(0 0 10px #444)",
    },
    banner: {
        position: "absolute",
        bottom: "0",
        backgroundColor: "#cccccccc",
        backdropFilter: "blur(4px)",
        width: "100%",
        textAlign: "right",
        [theme.breakpoints.up("lg")]: {
            textAlign: "center",
        },
    },
    bannerText: {
        fontSize: "2rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.6rem",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem",
        },
        color: theme.palette.primary,
    },
    button: {
        margin: "0.5rem",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main
    }
}));

export default function DisplayBanner() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container className={classes.root}>
                <Grid item sm={12} className={classes.slides}>
                    <SlideShow></SlideShow>
                </Grid>
                <Grid container item sm={12} className={classes.photoGrid}>
                    <Grid item xs={12}>
                        <Paper className={classes.banner}>
                            <Typography className={classes.bannerText}>
                                Call me today at{" "}
                                <a className={classes.link} href="tel:647-502-5054">647-502-5054</a> !!!
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <img
                            src={"/amrish_no_bg.png"}
                            alt="amrish"
                            className={classes.image}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    sm={12}
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classes.highlightGrid}
                >
                    <Grid item xs={6}>
                        <Paper className={classes.highlightContent}>
                            <img
                                src="/logo_stacked_nobg.png"
                                alt="logo"
                                className={classes.logo}
                            />
                            <Typography className={classes.highlightText}>
                                Bringing you closer to your dreams ...
                            </Typography>
                            <Hidden mdDown>
                                <CallToAction variant="contained" />
                            </Hidden>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden lgUp>
                <Grid container justifyContent="center" style={{marginTop: "2rem"}}>
                    <CallToAction variant="outlined" />
                </Grid>
            </Hidden>
        </React.Fragment>
    );
}
