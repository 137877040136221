import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/styles';
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
    carousel: {
        height: '100%',
        overflow: "hidden",
        "& *": {
            height: "100%"
        }
    },
    imageContainer: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    image: {
        flexShrink: 0,
        width: "100%",
        height: "100%",
    }

}))
export default function SlideShow() {
    const classes = useStyles();
    return (
        <Carousel autoPlay={true} showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} infiniteLoop={true} dynamicHeight={false} className={classes.carousel}>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1489370321024-e0410ad08da4.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1480074568708-e7b720bb3f09.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1565402170291-8491f14678db.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1592595896551-12b371d546d5.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1605276374104-dee2a0ed3cd6.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1516455590571-18256e5bb9ff.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1592595896616-c37162298647.jpeg"/>
            </div>
            <div className={classes.imageContainer}>
                 <StaticImage className={classes.image} alt="slideshowImg" src="../../static/photo-1611216212569-d739dbe9ed40.jpeg"/>
            </div>
        </Carousel>
    );
}
