import React from "react";
import Layout from "../components/layout";
import { Grid, makeStyles } from "@material-ui/core";
import DisplayBanner from "../components/displayBanner";
import Testimonials from "../components/testimonials";
import Intro from "../components/intro";
import CallToAction from "../components/callToAction";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "1rem 1rem 1rem 0",
        fontWeight: "bold",
        width: "300px",
    },
    separator: {
        width: "75vw",
    },
}));

const IndexPage = () => {
    const classes = useStyles();

    return (
        <Layout>
            <Grid>
                <DisplayBanner></DisplayBanner>
                <Intro></Intro>
                <Testimonials></Testimonials>
                <hr className={classes.separator} />
                <Grid container justifyContent="center">
                    <CallToAction variant="contained"/>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default IndexPage;
