import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "2rem",
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },

    },
    paragraph: {
        margin: "1rem auto",
        maxWidth: "70vw",
    },
    text: {
        fontSize: "1.1rem",
    },
    imageContainer: {
        textAlign: "center",
    },
    image: {
        width: "40%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },
        margin: "auto",
    },
    separator: {
        width: "75vw",
    },
}));

export default function Intro() {
    const classes = useStyles();
    return (
        <Grid container className={classes.container}>
            <Grid item className={classes.paragraph}>
                <Typography className={classes.text}>
                    I completed my Masters in Economics back in the 90's.
                    Followed by more than 20 years of experience in Sales &
                    Marketing, I bring a wide set of skills and expertise to the
                    table. My background makes it easier for me to understand
                    the nuances of the ever evolving Real Estate market.
                </Typography>
            </Grid>
            <hr className={classes.separator} />
            <Grid item className={classes.paragraph}>
                <Typography className={classes.text}>
                    Real Estate Market carries this stigma of being overly
                    complicated. People not being educated on things leads to
                    them having a biased perception. As a sales person, it is my
                    task to educate you on all the intricacies and keep your
                    life stress free. Buying and Selling should not be a complex
                    process. I value transparency in conversations and this
                    establishes trust from the get go.
                </Typography>
            </Grid>
            <Grid container item className={classes.imageContainer}>
                <img src="/house_market.svg" alt="house_market" className={classes.image} />
            </Grid>
            <Grid item className={classes.paragraph}>
                <Typography className={classes.text}>
                    Over the past 5 years alone I have several hundred happy
                    clients. As family man myself, I understand the care and
                    love we put into our homes. I realize how valuable your home
                    is to you and I consider it my responsibility to get you a
                    deal that is nothing short of your expectations.
                </Typography>
            </Grid>
            <hr className={classes.separator} />
        </Grid>
    );
}
